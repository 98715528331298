<template>
  <ZMainPage type="card">
    <el-card shadow="never" class="border-none mb-2">
      <template #header><span class="tb">基础信息</span></template>
      <LineWrapper labelWidth="150rem" class="pl-3">
        <LineItem v-for="(item, index) in list1" :key="index" :label="item.label" :value="item.value"></LineItem>
      </LineWrapper>
    </el-card>
    <el-card shadow="never" class="border-none mb-2">
      <template #header><span class="tb">缴费详情</span></template>
      <LineWrapper labelWidth="150rem" class="pl-3">
        <LineItem v-for="(item, index) in list2" :key="index" :label="item.label" :value="item.value"></LineItem>
      </LineWrapper>
    </el-card>
    <el-card shadow="never" class="border-none">
      <el-button type="primary" @click="$router.back()">返回</el-button>
      <el-button @click="handleVisible(0)">缴费记录</el-button>
      <el-button @click="handleVisible(1)">变更记录</el-button>
    </el-card>
    <Dialog :visible.sync="visible" :title="title" :showFooter="false">
      <TimeLine :list="list3" reverse v-if="!type">
        <template #[index] v-for="(item, index) in list3">
          <PaymentItem :item="item"></PaymentItem>
        </template>
      </TimeLine>
      <TimeLine :list="list4" reverse v-else></TimeLine>
    </Dialog>
  </ZMainPage>
</template>

<script>
import Dialog from "@/components/common/Dialog";
import TimeLine from "@/components/common/TimeLine";
import LineItem from "@/components/common/LineItem";
import LineWrapper from "@/components/common/LineWrapper";
import PaymentItem from "../components/PaymentItem";
import {as2_pay_history, as2_pay_record, as2_pay_show} from "@/api";
import {pageLoadingMixin} from "@/utils/mixins";

export default {
	_config:{"route":{"path":"detail","meta":{"title":"查看"}}},
  components: { Dialog, TimeLine, LineItem, LineWrapper, PaymentItem },
  mixins: [pageLoadingMixin],
  data() {
    return {
      visible: false,
      title: '',
      type: 0,
      list1: [],
      list2: [],
      list3: [],
      list4: []
    }
  },
  created() {
    this.getData()
  },
  methods: {
    getData() {
      this.httpStart()
      as2_pay_show({ id: this.$route.query.id }).then(res => {
        const list1 = [
          { label: '学生姓名', value: res.student_name },
          { label: '学号', value: res.student_no },
          { label: '校区', value: res.school },
          { label: '年级', value: res.grade },
          { label: '转入行政班', value: res.class },
          { label: '学习方向', value: res.direction },
          { label: '专业方向', value: res.major },
					{label: '转入艺体时间', value: res.art_at},
          { label: '转入审批通过时间', value: res.approve_time }
        ]
        const list2 = [
          { label: '应缴总金额', value: res.payable_amount },
          { label: '实缴总金额', value: res.paidin_amount },
          { label: '费用缴清时间', value: res.payment_time }
        ]
        if(res.reason) {
          list2.splice(1, 0, { label: '更改总金额原因', value: res.reason })
        }
        this.list1 = list1
        this.list2 = list2
      }).finally(() => this.httpEnd())

      as2_pay_record({ id: this.$route.query.id }).then(res => {
        const list = res.map(item => ({ date: item.pay_at, total: item.paidin_amount, fee: item.outstanding_amount, action: item.creator, time: item.created_at }))
        this.list3 = list
      })
      as2_pay_history({ tbl_id: this.$route.query.id, tbl_name: 'art_pay' }).then(res => {
        const { list = [] } = res
        this.list4 = list.map(i => ({ content: i.change_detail }))
      })
    },
    handleVisible(type) {
      this.title = type ? '变更记录' : '缴费记录'
      this.visible = true
      this.type = type
    }
  }
}
</script>

<style lang="scss" scoped></style>
